import errorHandler from "@/utils/handlers/errorHandler/ErrorHandler";

export const actions = {
  /** Loading spinner **/
  /**
    * @param {Boolean} isLoading
    */
  setLoading(isLoading) {
    this.isLoading = isLoading;
  },

  /** API Error **/
  /**
    * @param {string} error - Ex. invalid_credentials
    */
  setApiError(error) {
    this.apiError = errorHandler.getError(error.response.data).getMessage();
  },

  /** Modal **/
  /**
   * @param {object} modal
   * @param {object} modal.modalContent The modal content
   * @param {string} modal.activeModal The name of the active modal
   */
  setModalOptions({modalContent, activeModal}) {
    this.modalContent = modalContent;
    this.activeModal = activeModal;
    this.isModalShown = true;
  },

  resetModalOptions() {
    this.modalContent = null;
    this.isModalShown = false;
    this.activeModal = null;
  },

  /**
    * @param {String} activeModal - The name of the active modal
    */
  setActiveModal(activeModal) {
    this.activeModal = activeModal;
  },

  /** Pagination **/
  setPaginationData(data) {
    this.paginationData = data;
  },

  resetPaginationData() {
    this.paginationData = null;
  },
}
