import {ERROR_CODES} from '../errorCodes'
import ErrorBase from './ErrorBase'

export default class InvalidCredentials extends ErrorBase {
  constructor(error, message) {
    super(error, message);
    this.code = ERROR_CODES.INVALID_CREDENTIALS;
    this.message = {
      en: 'Invalid username and password combination',
      nl: 'Ongeldige combinatie van gebruikersnaam en wachtwoord',
    }
  }
}
