<template>
  <router-view/>
  <LoadingSpinner :is-loading="isLoading"/>
</template>

<script>
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import {useRootStore} from "@/store";
import {computed} from "vue";

export default {
  components: {
    LoadingSpinner
  },
  setup() {
    const store = useRootStore();

    /** Loading indicator **/
    const isLoading = computed(() => store.appIsLoading);

    return {
      isLoading
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/css/_layout.shared.scss';
</style>
