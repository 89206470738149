import apiHandler from '@/utils/handlers/ApiHandler';
import {useAuthStore} from '@/store/auth'

export const apiActions = {
  resetPassword(payload) {
    const store = useAuthStore();

    return apiHandler.post('/auth/reset-password', payload).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    }).catch(error => {
      store.loginFailure(error);
      return false
    });
  },
}
