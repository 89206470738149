import LocalDataHandler from "@/utils/storage/LocalDataHandler";
import Cookies, {COOKIE_KEYS} from "@/utils/storage/Cookies";
import {AuthenticationProviders} from "@/utils/authenticationManager/providers/providers";

const AUTHENTICATED = '1'
class AuthDataHandler extends LocalDataHandler {
  constructor() {
    super();
  }

  setAccessToken(token, expireIn) {
    Cookies.set({
      cookieKey: COOKIE_KEYS.ACCESS_TOKEN,
      cookieValue: token,
      duration: expireIn,
      domain: `${process.env.VUE_APP_HOST}`
    });
    this.setAccessTokenExpiration(expireIn);
  }

  getAccessToken() {
    const token = Cookies.get(COOKIE_KEYS.ACCESS_TOKEN);

    // Case when the user didn't check "keepMeLoggedIn" but come back within 1 hour.
    if (token && !this.keepMeLoggedIn) {
      sessionStorage.setItem(this.KEYS.IS_AUTHENTICATED, AUTHENTICATED);
    }

    return token;
  }

  setAccessTokenExpiration(expiresIn) {
    const expirationDate = new Date();
    expirationDate.setSeconds(expirationDate.getSeconds() + expiresIn);
    Cookies.set({
      cookieKey: COOKIE_KEYS.EXPIRES_IN,
      cookieValue: expirationDate.getTime(),
      duration: expiresIn,
      domain: `${process.env.VUE_APP_HOST}`
    });
  }

  getAccessTokenExpiration() {
    return Cookies.get(COOKIE_KEYS.EXPIRES_IN);
  }

  getRefreshToken() {
    if (this.keepMeLoggedIn || sessionStorage.getItem(this.KEYS.IS_AUTHENTICATED)) {
      return Cookies.get(COOKIE_KEYS.REFRESH_TOKEN);
    }
    return null;
  }

  setRefreshToken(token, expireIn) {
    Cookies.set({
      cookieKey: COOKIE_KEYS.REFRESH_TOKEN,
      cookieValue: token,
      duration: expireIn,
      domain: `${process.env.VUE_APP_HOST}`
    });
  }

  /**
   * Authentication Manager
   **/
  setAuthenticationProvider(provider) {
    this.saveLocalData('provider', provider);
  }

  getAuthenticationProvider() {
    const provider = this.getLocalData('provider');
    if (!provider) {
      return AuthenticationProviders.emailPassword.name;
    }
    return provider;
  }

  /**
   * functions to set "is_authenticated" value, mainly for "cookie domain" login
   **/
  setAuthenticated() {
    this.saveLocalData(this.KEYS.IS_AUTHENTICATED, '1');
  }

  isAuthenticated() {
    return !!this.getLocalData(this.KEYS.IS_AUTHENTICATED);
  }

  removeAuthenticated() {
    this.removeLocalData(this.KEYS.IS_AUTHENTICATED);
  }

  /** functions to handle keep me logged in **/
  setKeepMeLoggedIn({keepMeLoggedIn}) {
    // Save the keep me logged in to local storage since it persistent for future use.
    if (keepMeLoggedIn) {
      return this.saveLocalData(this.KEYS.KEEP_ME_LOGGED_IN, this.VALUES.KEEP_ME_LOGGED_IN);
    }

    // If not, set a key in sessionStorage, if the key is gone (all tabs closed), the user can't get refresh token to
    // refresh access token (considered not "keepMeLoggedIn"), check getRefreshToken() call.
    sessionStorage.setItem(this.KEYS.IS_AUTHENTICATED, '1');
  }

  get keepMeLoggedIn() {
    // get the keep me logged in value
    return localStorage.getItem(this.KEYS.KEEP_ME_LOGGED_IN);
  }

  clearKeepMeLoggedIn() {
    // remove keep me logged in
    localStorage.removeItem(this.KEYS.KEEP_ME_LOGGED_IN);
  }

  static getInstance() {
    if (this.instance == null) {
      this.instance = new AuthDataHandler();
    }
    return this.instance;
  }
}

export default AuthDataHandler.getInstance();
