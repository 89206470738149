import {createRouter, createWebHistory} from 'vue-router';
import modules from '@/router/modules';
import {ROUTE_NAMES_ROOT} from "@/router/modules/index";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: modules,
  scrollBehavior() {
    // Always scroll to the top of the page.
    return {top: 0};
  },
});

router.beforeEach(async (to, from, next) => {
  // User enters an invalid URL.
  if (to.matched.length === 0) {
    // TODO: Change depending on workflow.
    // return next({name: ROUTE_NAMES_AUTH.LOGIN});
    return next({name: ROUTE_NAMES_ROOT.NOT_FOUND});
  }

  // User enters a valid URL.
  for (const route of to.matched) {
    if (route.meta && route.meta.guard) {
      /** Let each guard handle route access **/
      const result = await route.meta.guard.handleRouteEnter();
      if (result !== true) {
        // TODO: CHECK AND CHANGE TO PINIA
        // TODO: store.commit(SET_LOGOUT);
        return next({name: result});
      }
    }
  }

  return next();
});

export default router;
