export const getters = {
  /** API Loading **/
  appIsLoading: (state) => state.isLoading,

  /** Modal **/
  getModalOptions: (state) => state.modalContent,
  getIsModalShown: (state) => state.isModalShown,
  getActiveModal: (state) => state.activeModal,

  /** Pagination **/
  getPaginationData: (state) => state.paginationData,

  /** API Error **/
  getApiError: (state) => state.apiError,
}
