/** routes **/
import NotFound from '@/views/NotFound'

const ResetPassword = () => import('@/views/ResetPassword.vue')
const TermsOfService = () => import('@/views/TermsOfService.vue')
const PrivacyPolicy = () => import('@/views/PrivacyPolicy.vue')

export const ROUTE_NAMES_ROOT = Object.freeze({
  NOT_FOUND: 'Not Found',
  RESET_PASSWORD: 'ResetPassword',
  TERMS_OF_SERVICE: 'Terms of Service',
  PRIVACY_POLICY: 'Privacy Policy',
});

/** routes without layout **/
export const root = [
  {
    // not found
    path: '/not-found',
    name: ROUTE_NAMES_ROOT.NOT_FOUND,
    component: NotFound
  },
  {
    // reset password
    path: '/auth/reset-password/:uuid',
    name: ROUTE_NAMES_ROOT.RESET_PASSWORD,
    component: ResetPassword,
  },
  {
    path: '/terms-of-service',
    name: ROUTE_NAMES_ROOT.TERMS_OF_SERVICE,
    component: TermsOfService,
  },
  {
    path: '/privacy-policy',
    name: ROUTE_NAMES_ROOT.PRIVACY_POLICY,
    component: PrivacyPolicy,
  }
]

export default root